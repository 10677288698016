<template>
  <div ref="TabNavbar">
    <div class="tab-navbar-pill shadow-sm" :style="cssVars" :class="{ 'slant-navbar': slant }">
      <router-link
        v-for="(option, index) in options"
        :key="`${option.to.replace(/\s/g, '')}-${index}`"
        :to="{ name: option.to }"
        class="tab-navbar-link"
      >
        <div
          class="tab-navbar-option"
          :class="{
            selected: isCurrentPage(option),
            'slant-option': slant,
          }"
        >
          <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'top'">
            <p
              class="tab-navbar-option-text"
              :class="{ 'slant-option-text': slant }"
              :title="option.tooltip"
              :style="option.text === 'Subscribe' ? styleText : ''"
            >
              {{ option.text }}
            </p>
            <template v-slot:myTooltipTemplate="{ props }">
              <a>
                <strong>{{ props.title }}</strong>
              </a>
            </template>
          </Tooltip>
        </div>
      </router-link>
    </div>
    <div class="tab-navbar-mobile">
      <router-link :to="{ name: backArrowRoute }" class="tab-navbar-link">
        <div
          class="tab-navbar-arrow-bubble shadow-sm"
          :class="{ disabled: options[0] === selectedTab }"
          @click.self="goBack"
        >
          ⏴
        </div>
      </router-link>

      <div class="tab-navbar-option">
        <p
          class="tab-navbar-option-text"
          :style="selectedTab.text === 'Subscribe' ? styleText : ''"
        >
          {{ selectedTab.text }}
        </p>
      </div>

      <router-link :to="{ name: forwardArrowRoute }" class="tab-navbar-link">
        <div
          class="tab-navbar-arrow-bubble shadow-sm"
          :class="{ disabled: options[options.length - 1] === selectedTab }"
          @click.self="goForward"
        >
          ⏵
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { Tooltip } from "@progress/kendo-vue-tooltip";

export default {
  components: {
    Tooltip,
  },
  props: ["options", "slant"],
  data: function () {
    return {
      styleText: {
        textDecoration: "underline",
        fontWeight: "bold",
      },
    };
  },
  methods: {
    goBack() {
      if (this.options[0] === this.selectedTab) {
        return;
      }

      let selectedIndex = this.options.findIndex((o) => o.to === this.$route.name);
      this.selectedOption = this.options[selectedIndex - 1];
    },
    goForward() {
      if (this.options[this.options.length - 1] === this.selectedOption) {
        return;
      }

      let selectedIndex = this.options.findIndex((o) => o === this.selectedOption);
      this.selectedOption = this.options[selectedIndex + 1];
    },
    isCurrentPage(option) {
      return this.$route.name === option.to;
    },
  },
  computed: {
    selectedTab() {
      return this.options.find((o) => o.to === this.$route.name);
    },
    backArrowRoute() {
      if (this.options[0] === this.selectedTab) {
        return "";
      }

      let selectedIndex = this.options.findIndex((o) => o === this.selectedTab);
      return this.options[selectedIndex - 1].to;
    },
    forwardArrowRoute() {
      if (this.options[this.options.length - 1] === this.selectedTab) {
        return "";
      }
      let selectedIndex = this.options.findIndex((o) => o === this.selectedTab);
      return this.options[selectedIndex + 1].to;
    },
    cssVars() {
      const selectedIndex = this.options.findIndex((o) => o === this.selectedTab);
      return {
        "--percentage": 14 * (selectedIndex + 1) + "%",
      };
    },
  },
};
</script>
