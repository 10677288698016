<template>
  <section>
    <tab-navbar class="mb-4" :options="tabs"></tab-navbar>
    <router-view class="mr-t-4" @handleSave="changeTab" />
  </section>
</template>
<script>
import TabNavbar from "../../components/Common/TabNavbar.vue";

export default {
  props: ["companyID"],
  data() {
    return {
      tabs: [
        {
          text: "Subscribe",
          to: "EnewsSignup",
          tooltip:
            "Stay in the know by selecting your preferred ACHC e-newsletter(s) for delivery direct to your inbox",
        },
        { text: "Blog", to: "NewsBlog", tooltip: "A range of short articles, sortable by program" },
        //{ text: "ACHC Today", to: "ACHCToday" },
        {
          text: "Did You Know Emails",
          to: "DidYouKnowEmails",
          tooltip:
            "A monthly newsletter to deepen understanding of individual ACHC standards, quality concepts, or other program-focused topics",
        },
        {
          text: "PCAB Today",
          to: "PCABToday",
          tooltip:
            "A monthly newsletter for compounding pharmacies. Every month we take a deep dive into a standard or quality concept and share upcoming educational events",
        },
      ],
    };
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      tabsWithTooltip: this.tabs,
    };
  },
  components: {
    "tab-navbar": TabNavbar,
  },
  methods: {
    changeTab(route) {
      var currentTabIndex = this.tabs.findIndex((t) => t.to === route);
      if (currentTabIndex !== this.tabs.length - 1) {
        this.$router.push({
          name: this.tabs[currentTabIndex + 1].to,
          params: { companyID: this.companyID },
        });
      }
    },
  },
};
</script>
